var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"wrapper wrapper-size-sm",class:_vm.getWrapperClass},[_c('div',{staticClass:"label-area"},[_vm._t("labels",function(){return [_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" "),(_vm.haveSyncButton)?_c('span',{on:{"click":function($event){!_vm.isSyncButtonSpinning && _vm.$emit('sync')}}},[_c('i',{staticClass:"fas fa-rotate ml-1  ",class:_vm.isSyncButtonSpinning
                  ? 'fa-spin cursor-not-allowed'
                  : 'cursor-pointer hover:text-black'})]):_vm._e()])]})],2),_c('div',{staticClass:"bell-area",on:{"click":function($event){return _vm.handleAlarm()}}},[(_vm.type === 'RESTART')?_c('img',{staticClass:"bell",class:_vm.getBellIconCLass,attrs:{"src":require("@/assets/icon/power.svg")}}):_vm._e(),(
          _vm.type === 'UNLOCK_BATTERY' ||
            _vm.type === 'UNLOCK_SADDLE' ||
            _vm.type === 'UNLOCK_TAILBOX' ||
            _vm.type === 'UNLOCK_SEAT' ||
            _vm.type === 'UNLOCK_HELMET'
        )?_c('img',{staticClass:"bell",class:_vm.getBellIconCLass,attrs:{"src":require("@/assets/icon/battery_unlock.svg")}}):_vm._e(),(_vm.type === 'LOCK_BATTERY')?_c('img',{staticClass:"bell",class:_vm.getBellIconCLass,attrs:{"src":require("@/assets/icon/lock-white.svg")}}):_vm._e(),(_vm.type === 'RING')?_c('img',{staticClass:"bell",class:_vm.getBellIconCLass,attrs:{"src":require("@/assets/icon/bell-icon.svg")}}):_vm._e(),_c('div',{staticClass:"mx-2 w-7 font-medium text-center text-14px text-oGray"},[_vm._v(" "+_vm._s(_vm.getBellTimerText)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }